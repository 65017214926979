import { createSelector } from "reselect";
import type { RootState } from "./store";

export const makeSelectSettings = () =>
  createSelector(
    (state: RootState) => state.app,
    (app) => app.settings
  );

export const makeSelectUser = () =>
  createSelector(
    (state: RootState) => state.app,
    (app) => app.user
  );
