import { combineReducers } from "@reduxjs/toolkit";
import { App } from "./app";
import { Send } from "./send";
import { Events } from "./events";
import { Requests } from "./requests";
import { CallEvents } from "./call";

const rootReducer = combineReducers({
  app: App,
  send: Send,
  events: Events,
  requests: Requests,
  calls: CallEvents,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
