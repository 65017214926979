import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";

import {
  IonPage,
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonBackButton,
} from "@ionic/react";
//API
import ContactsApi from "../../../api/contacts";
import ContactDetails from "../../../components/ContactDetails";
import { Contact } from "../../../interfaces";
import { useSelectContactById } from "../../../store/contacts";

const ContactDetail: React.FC<ILayoutProps> = (props: ILayoutProps) => {
  const { id } = props.match.params;

  const selectedChat = useSelectContactById(id);

  const solveEscalations = async (id: string) => {
    const { err } = await ContactsApi.putSolveEscalations(id);
    if (err) {
      console.log(err);
    }
  };
  const createEscalation = async (contactId: string) => {
    await ContactsApi.escalateContact(contactId, {});
  };

  const toggleBlock = async (contact: Contact) => {
    const { id, blocked } = contact;

    const { err } = await ContactsApi.putBlocked(id, { blocked: !blocked });
    if (err) {
      console.log(err);
    }
  };

  return (
    <>
      <IonPage id="main-content">
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton defaultHref={`/chat/${id}`} />
            </IonButtons>
            <IonTitle>Info</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <ContactDetails
            bookings={selectedChat.bookings}
            contact={selectedChat.contact}
            solveEscalations={solveEscalations}
            createEscalation={createEscalation}
            toggleBlock={toggleBlock}
          />
        </IonContent>
      </IonPage>
    </>
  );
};

interface ILayoutProps extends RouteComponentProps<{ id: string }> {
  dispatch: Function;
}

export default connect((props: any) => ({}))(ContactDetail);
